<template>
  <div class="d-flex align-items-center justify-content-center h-100">
    <LoadingContent :isLoading="form.email && form.email.length < 1" />
    <b-card no-body style="flex: 0.5">
      <b-card-header header-bg-variant="transparent" class="d-flex justify-content-end">
        <b-button variant="light" class="mr-2" size="sm" @click="clearForm">{{
          $t("CLEAR")
        }}</b-button>
        <b-button class="text-white" @click="$router.push({ name: 'UsersList' })">
          {{ $t("BACK") }}
        </b-button>
      </b-card-header>
      <b-card-body>
        <b-form @submit.prevent="editFormSubmit" v-if="getUser">
          <b-form-group
            label-cols-lg="3"
            :label="$t('USER.' + fieldName)"
            label-size="sm"
            v-for="fieldName in editableFieldUser"
            :key="fieldName"
          >
            <multi-select
              v-if="fieldName === 'roles'"
              multiple
              :options="roles"
              placeholder="Wybierz role"
              noOptions="Brak ról"
              v-model="form.roles"
              class="h-100"
            />

            <b-checkbox
              v-else-if="fieldName === 'enabled'"
              switch
              class="mt-1"
              v-model="$v.form.enabled.$model"
              :checked="form.enabled"
              aria-describedby="editRoleActiveFeedback"
            />
            <b-form-input
              :placeholder="$t(`USER.${fieldName}`)"
              v-model="$v.form[fieldName].$model"
              :state="validateState(fieldName)"
              :aria-describedby="`editUser${fieldName}Feedback`"
              v-else
            />

            <b-form-invalid-feedback :id="`editUser${fieldName}Feedback`">
              {{ $t("VALID.required", { name: $t(`USER.${fieldName}`) }) }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-button type="submit" variant="primary" class="text-white ml-auto d-block">
            Wyślij
          </b-button>
        </b-form>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import required from "vuelidate/lib/validators/required";
import { mapGetters } from "vuex";
import { EDIT_USER, GET_USER } from "@/core/store/Users/user.module";
import { GET_ROLES } from "@/core/store/Roles/roles.module";
import LoadingContent from "@/components/LoadingContent/LoadingContent.vue";

export default {
  name: "editRoleForm",
  components: { LoadingContent },
  data() {
    return {
      form: {
        email: null,
        firstName: null,
        lastName: null,
        phone: null,
        roles: {},
      },
      roles: [],
      editableFieldUser: ["email", "firstName", "lastName", "phone", "roles", "enabled"],
    };
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    clearForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = null;
      });
    },
    editFormSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$store
        .dispatch(EDIT_USER, this.form)
        .then(() => {
          this.toastSuccess("User role success", "SUCCESS");
          this.$v.form.$reset();
        })
        .catch((err) => {
          this.toastError(err.data.message, "ERROR");
        });
    },
    setFormValue(data) {
      Object.keys(data).forEach((key) => {
        this.form[key] = data[key];
      });
    },
  },
  validations: {
    form: {
      email: {
        required,
      },
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      phone: {
        minLength: 0,
      },
      enabled: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters(["getUser", "getRoles"]),
  },
  mounted() {
    this.$store.dispatch(GET_ROLES).then(({ data }) => {
      // eslint-disable-next-line array-callback-return
      data.list.map((i) => {
        this.roles.push(i.name);
      });
    });
    if (!this.$route.params.user) {
      this.$store.dispatch(GET_USER, this.$route.params.id).then((resp) => {
        const { data } = resp;
        this.setFormValue(data);
      });
    } else {
      this.setFormValue(this.$route.params.user);
    }
  },
};
</script>
